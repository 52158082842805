import {httpClient} from "@/libs/http";
import {checkUserLoginPermission, checkUserLoginModule} from "@/libs/helpers";

export default async function auth({ next, router, to }) {
    const apt = localStorage.getItem('_apt')
    if (!apt) {
        return router.push({ name: 'Login' });
    }
    try {
        if (!window.me) {
            const {data} = await httpClient.post('/me/v1/admin')
            window.me = {...data}
        }
        const userPermission = window.me && window.me.permissions || []
        const checkPermission = checkUserLoginPermission(userPermission, to.meta.permissions)

        const userModule = window.me && window.me.modules || []
        const checkModule = checkUserLoginModule(userModule, to.meta.modules)

        if (checkPermission && checkModule) {
            return next();
        }
        return next('/403');
    } catch (e) {
        console.error('getMe->ERROR:', e)
        return next('/403');
    }
}
